import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {Flex, Card, Divider, Heading, Image as Img, Paragraph} from 'reakit'

import {
	Container,
	Sidebar,
	Body,
	Footer,
	Form,
} from '../../components/layouts'

import { Loader } from '../../components/ui'

import Layout from '../../components/layout'

import {
	getOrgCategories,
	editOrg,
	refreshToken
} from '../../factory'

import theme from '../../theme/content'

class Organization extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			imageOrg: null,
			categories: [],
			success: '',
		}

		this.content = theme.pagesContent.newOrganization

		this.setValues = this.setValues.bind(this)
		this.validateValues = this.validateValues.bind(this)
		this.submitForm = this.submitForm.bind(this)
		this.getOptionsCategories = this.getOptionsCategories.bind(this)
		this.preloadFormData = this.preloadFormData.bind(this)
	}

	componentDidMount() {
		this.props.onGetOrgCategories()
		if (this.props.categories.length > 0) {
			this.getOptionsCategories()
		}
		this.preloadFormData()
		this.props.refreshToken()
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.categories.length !== nextProps.categories.length) {
			this.getOptionsCategories()
		}
	}

	setValues(e) {
		const target = e.target
		const name = e.target.name
		const value = e.target.value

		if (target) {
			this.setState({
				[name]: value,
			})

			if (name === 'image_url') {
				var logo = new Image()
				logo.src = e.target.value

				logo.onload = () => {
					this.setState({
						image_url: logo.src,
					})
				}
			}
		}
	}

	validateValues() {
		if (!this.state.title) {
			this.setState({error: 'Title must be populated.', success: ''})
			return false
		} else if (!this.state.category_id) {
			this.setState({error: 'Category must be populated.', success: ''})
			return false
		} else if (!this.state.phone) {
			this.setState({error: 'Phone number must be populated.', success: ''})
			return false
		} else if (!this.state.email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)) {
			this.setState({error: 'Email must be populated or use the correct format.', success: ''})
			return false
		} else if (!this.state.website) {
			this.setState({error: 'Website URL must be populated.', success: ''})
			return false
		} else if (!this.state.image_url) {
			this.setState({error: 'Image URL must be populated.', success: ''})
			return false
		} else if (!this.state.street_address) {
			this.setState({error: 'Address must be populated.', success: ''})
			return false
		} else if (!this.state.city) {
			this.setState({error: 'City must be populated.', success: ''})
			return false
		} else if (!this.state.state) {
			this.setState({error: 'State must be populated.', success: ''})
			return false
		} else if (!this.state.zip) {
			this.setState({error: 'ZIP must be populated.', success: ''})
			return false
		} else if (!this.state.ein) {
			this.setState({error: 'EIN must be populated.', success: ''})
			return false
		}

		this.setState({error: ''})
		return true
	}

	submitForm(e) {
		e.preventDefault()

		if (this.validateValues()) {
			this.props.onUpdateOrg(this.state, this.props.token, () => {
				this.setState({
					success: 'Organization has been updated.'
				})
			})
		}
	}

	getOptionsCategories() {
		var categories = []

		this.props.categories.map((item, index) => {
			categories[index] = {
				text: item.title,
				value: item.id,
			}
		})

		this.setState({
			categories: categories,
		})
	}

	preloadFormData() {
		const organization = Object.assign({}, this.props.organization, this.props.organization.address)
		Object.keys(organization).map((key) => {
			this.setState({
				[key]: organization[key] && typeof(organization[key]) === 'object' ? organization[key].hasOwnProperty('id') ? organization[key].id : organization[key] : organization[key]
			})
		})
	}

	render() {
		return (
			<Layout>
				<Container maxHeight="100vh">
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<Flex
							flex="0 0"
							alignItems="start"
							justifyContent="space-between"
							marginBottom="5em"
							width="100%"
							row
						>
							<Flex maxWidth="calc(70% - 1em)" width="100%">
								<Card
									borderRadius="5px"
									padding="1em"
									width="100%"
								>
									<Flex row alignContent="flex-start">
										<Heading as="h2" margin="auto 0" marginRight="1em">
											{this.state.title}
										</Heading>
										{this.props.pending && <Loader style={{margin: 0}}/>}
									</Flex>
									{this.state.success !== '' && <Paragraph>{this.state.success}</Paragraph>}
									<Divider
										borderColor={theme.colors.dustGray}
										margin="0 0 1rem 0"
										maxWidth="100%"
									/>
									<Flex margin="0 0 1em 0" column>
										<Form
											fields={this.content.createForm.fields}
											selectOptions={{category_id: this.state.categories}}
											setValues={this.setValues}
											submitForm={this.submitForm}
											submitBtnText={this.content.createForm.submitField.text}
											defaultValues={this.state}
											values={this.state}
											disabled={false}
											error={this.props.error || this.state.error}
										/>
									</Flex>
								</Card>
							</Flex>
							<Flex maxHeight="400px" maxWidth="30%" width="100%">
								<Card
									backgroundImage={`url(${this.content.bgRightColum})`}
									backgroundSize="cover"
									borderRadius="5px"
									padding="1em"
									height="300px"
									width="100%"
								>
									{this.state.image_url && (
										<Img
											src={this.state.image_url}
											margin="0 auto"
											maxWidth="90%"
											width="100%"
										/>
									)}
								</Card>
							</Flex>
						</Flex>
						<Flex
							flex="0 0"
							alignItems="start"
							marginLeft="-1em"
							width="calc(100% + 2em)"
							row
						>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

Organization.propTypes = {
	categories: PropTypes.array,
	organization: PropTypes.object,
	token: PropTypes.string,
	pending: PropTypes.bool,
	error: PropTypes.any,
	onUpdateOrg: PropTypes.func,
	refreshToken: PropTypes.func,
	onGetOrgCategories: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		categories: state.adminState.orgCategories,
		organization: state.authState.adminOrg || state.adminState.organization,
		token: state.authState.accessToken,
		pending: state.adminState.pending,
		error: state.adminState.error,
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetOrgCategories: () => dispatch(getOrgCategories()),
		onUpdateOrg: (organization, token, callback) => { dispatch(editOrg(organization, token, callback)) },
		refreshToken: () => dispatch(refreshToken())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Organization)
